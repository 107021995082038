import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "styles/components/sharing-buttons.scss"
import { propTypes } from "react-bootstrap/esm/Image"

// Icons
import { IoLogoTwitter } from "react-icons/io"
import { IoMdMail } from "react-icons/io"
import { IoLogoLinkedin } from "react-icons/io"

function SharingButtons({ className, title, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            author {
              twitter
            }
            email
          }
        }
      }
    `
  )

  const encodedTitle = encodeURIComponent(title)
  const { twitter } = site.siteMetadata.author
  const url = `${site.siteMetadata.siteUrl}${path}`

  const twitterLink = `https://twitter.com/intent/tweet?via=${twitter}&text=“${encodedTitle}”&url=${url}`
  const emailLink = `mailto:?subject=${encodedTitle}&body=${url}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`

  return (
    <div className={`sharing-buttons ${className}`}>
      <a href={twitterLink} className="button" target="_blank" rel="noreferrer"><IoLogoTwitter /></a>
      <a href={emailLink} className="button"><IoMdMail /></a>
      <a href={linkedinLink} className="button" target="_blank" rel="noreferrer"><IoLogoLinkedin /></a>
    </div>
  )
}

SharingButtons.propTypes = {
  className: propTypes.string,
  title: propTypes.string,
  path: propTypes.string,
}

export default SharingButtons