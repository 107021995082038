import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import "./blog-post.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO"
import SharingButtons from "components/SharingButtons"

// Utilities
import kebabCase from "lodash/kebabCase"

export default function BlogPostTemplate({ data }) {
  const post = data.markdownRemark
  const frontmatter = post.frontmatter
  const image = post.frontmatter.image ? post.frontmatter.image.childImageSharp.resize : null
  const readingTime = Math.ceil(post.fields.readingTime.minutes)
  const postPath = post.fields.slug

  return (
    <Layout pathname="blog">
      <SEO
        title={frontmatter.title}
        description={post.excerpt}
        image={image.src}
        pathname={postPath}
      />
      <div className="blog-post container">
        <div className="header">
          <ul className="categories">
            {frontmatter.categories.map((category) => {
              return <li><Link to={`/blog/category/${kebabCase(category)}/`} className="category-item">{category}</Link></li>
            })}
          </ul>
          <h1 className="title">{frontmatter.title}</h1>
          <p className="date">Posted on {frontmatter.date}</p>
          <p className="author">By {frontmatter.author}</p>
          <div className="sharing-container">
            <div className="read-time">{readingTime} min read</div>
            <SharingButtons className="buttons" title={frontmatter.title} path={postPath} />
          </div>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        author
        title
        categories
        image: thumbnail {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          minutes
        }
      }
    }
  }
`